import React from "react";
import Logo from "../assets/images/Logo.png";
// import Image from "next/image";
import { FiChevronRight } from "react-icons/fi";
import { FaTelegramPlane, FaTwitter, FaDiscord } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="bg-dark-800 py-16">
      <div className="container">
        <div className="grid  md:grid-cols-12 gap-y-10 md:gap-6 ">
          <div className=" md:col-span-4 ">
            <img src={Logo} alt="logo" />
            <p className="text-light-400 text-sm">
              ONE OF A KIND LIMITED EDITION NFTS
            </p>
          </div>
          <div className="md:col-span-8 grid sm:grid-cols-3">
            <div>
              <h6 className=" text-light-400 text-base mb-6 font-bold">
                {footerLinks[0].title}
              </h6>
              <ul>
                {footerLinks[0].links.map((val, i) => (
                  <li key={i}>
                    <a
                      href={val.link}
                      className="flex items-center justify-start text-light-400 mb-4 text-sm"
                    >
                      {" "}
                      <span>
                        <FiChevronRight />
                      </span>{" "}
                      <span>{val.text}</span>{" "}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h6 className=" text-light-400 text-base mb-6 font-bold">
                {footerLinks[1].title}
              </h6>
              <ul>
                {footerLinks[1].links.map((val, i) => (
                  <li key={i}>
                    <a
                      href={val.link}
                      className="flex items-center justify-start text-light-400 mb-4 text-sm"
                    >
                      {" "}
                      <span>
                        <FiChevronRight />
                      </span>{" "}
                      <span>{val.text}</span>{" "}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className=" ">
              <h6 className=" text-light-400 sm:text-center text-base mb-6 font-bold">
                Social Media
              </h6>
              <div className=" grid grid-flow-col justify-start sm:justify-center items-center gap-x-2">
                {socialList.map((val, i) => (
                  <a href={val.link} className=" text-xl" key={i}>
                    {val.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const socialList = [
  {
    icon: <FaTwitter />,
    link: "#",
  },
  {
    icon: <FaDiscord />,
    link: "#",
  },
  {
    icon: <FaTelegramPlane />,
    link: "#",
  },
];

const footerLinks = [
  {
    title: "Quick Links",
    links: [
      {
        text: "Mint",
        link: "#",
      },
      {
        text: "Roadmap",
        link: "#",
      },
      {
        text: "Story",
        link: "#",
      },
      {
        text: "Team",
        link: "#",
      },
    ],
  },
  {
    title: "Support Links",
    links: [
      {
        text: "Privacy Policy",
        link: "#",
      },
      {
        text: "Terms & Conditions",
        link: "#",
      },
    ],
  },
];
