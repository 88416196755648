/* eslint-disable no-unused-vars */
import "./App.css";
import Main from "./Main";
import Web3 from "web3";
import { contractAbi, contractAddress } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import InformationModal from "./components/informationModal";
import ConfirmationLoadingPopup from "./components/confirmationLoadingPopup";
toast.configure();
function App() {
  const [chainId, setChainId] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  // const [totalSupply, setTotalSupply] = useState(0);
  // const [maxSupply, setMaxSupply] = useState(0);
  // const [price, setPrice] = useState(0);
  // const [displayPrice, setDisplayPrice] = useState(0);
  const [lessMintAmountAlert, setLessMintAmountAlert] = useState(false);
  const [accessAccountDenied, setAccessAccountDenied] = useState(false);
  const [installEthereum, setInstallEthereum] = useState(false);
  const [nftMinted, setNftMinted] = useState(false);
  const [nftMinting, setNftMinting] = useState(false);
  const [transactionRejected, setTransactionRejected] = useState(false);
  const [transactionFailed, setTransactionFailed] = useState(false);
  const [switchToMainnet, setswitchToMainnet] = useState(false);
  const [ethereumCompatibleBrowser, setEthereumCompatibleBrowser] =
    useState(false);
  const [mintingInProgress, setMintingInProgress] = useState(false);
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  // const [saleLive, setSaleLive] = useState(false);
  const [whitelist, setWhiteList] = useState(false);
  const [maxMint, setMaxMint] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [unClaimedBalance, setUnClaimedBalance] = useState(0);
  const [nftBalance, setNftBalance] = useState(0);
  const [tokensPerNft, setTokensPerNft] = useState(0);
  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.localStorage.setItem("account", "metaMask");
      try {
        loadBlockchainData();
        getCurrentAddressConnected();
        addAccountsAndChainListener();

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          setAccessAccountDenied(true);
        } else console.error(error);
      }
    } else {
      setInstallEthereum(true);
    }
  }

  useEffect(() => {
    const isLocalAccount = window.localStorage.getItem("account");
    if (isLocalAccount === "metaMask") {
      loadWeb3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  // const getBalance = async () => {
  //   const userBalance = await window.web3.eth.getBalance(account);
  //   const converted_balance = window.web3.utils.fromWei(userBalance, "ether");
  //   // console.log("userBalance", converted_balance);
  //   // console.log(account);
  //   setBalance(converted_balance);
  // };

  const loadBlockchainData = async () => {
    const contract = new window.web3.eth.Contract(contractAbi, contractAddress);
    setContract(contract);
    const chainId = await window.web3.eth.getChainId();
    setChainId(chainId);
    //success when chainId = 4 else failure
    // you are connected to main net
    // Please connect to main net

    if (chainId === 1) {
      toast(`You are connected to main net`, {
        type: "success",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      // console.log(account);

      try {
        const unClaimedRewards = await contract.methods
          .getUnclaimedRewards()
          .call({ from: account });
        setUnClaimedBalance(Web3.utils.fromWei(unClaimedRewards, "ether"));
        // console.log("unclaimed Balance", unClaimedBalance);

        const tokenBalance = await contract.methods
          .getTokenBalance()
          .call({ from: account });
        setTokenBalance(Web3.utils.fromWei(tokenBalance, "ether"));

        const nftBalance_ = await contract.methods
          .getNFTbalance()
          .call({ from: account });
        setNftBalance(nftBalance_);
        const tokensPerNft_ = await contract.methods.tokensPerNFT().call();
        setTokensPerNft(Web3.utils.fromWei(tokensPerNft_, "ether"));
        // console.log("nftBalance", nftBalance_);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      toast(`Please connect to Mainnet`, {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const getCurrentAddressConnected = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountsAndChainListener = async () => {
    //this event will be emitted when the currently connected chain changes.
    window.ethereum.on("chainChanged", (_chainId) => {
      window.location.reload();
    });

    // this event will be emitted whenever the user's exposed account address changes.
    window.ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  };
  async function claim() {
    if (contract) {
      if (chainId === 1) {
        setConfirmTransaction(true);
        contract.methods
          .claimRewards()
          .send({ from: account })
          .on("transactionHash", function () {
            setConfirmTransaction(false);
            setMintingInProgress(true);
          })
          .on("confirmation", function () {
            const el = document.createElement("div");
            el.innerHTML =
              "View minted NFT on OpenSea : <a href='https://testnets.opensea.io/account '>View Now</a>";

            setNftMinted(true);
            setConfirmTransaction(false);
            setMintingInProgress(false);
            setTimeout(() => {
              window.location.reload(false);
            }, 5000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              setTransactionRejected(true);
              setConfirmTransaction(false);
              setMintingInProgress(false);
            } else {
              setTransactionFailed(true);
              setConfirmTransaction(false);
              setMintingInProgress(false);
            }
          });
      } else {
        setswitchToMainnet(true);
      }
    } else {
      setEthereumCompatibleBrowser(true);
    }
  }

  return (
    <div className="App">
      <Main
        loadWeb3={loadWeb3}
        account={account}
        tokenBalance={tokenBalance}
        unClaimedBalance={unClaimedBalance}
        claim={claim}
        nftBalance={nftBalance}
        tokensPerNft={tokensPerNft}
      />
      <InformationModal
        open={maxMint}
        onClose={setMaxMint}
        title="Oops"
        text="You have reached maximum NFT minting limit per account!"
      />
      <InformationModal
        open={whitelist}
        onClose={setWhiteList}
        title="Oops"
        text="You are not whitelisted"
      />
      <InformationModal
        open={lessMintAmountAlert}
        onClose={setLessMintAmountAlert}
        title="Oops"
        text="value cant be less then 1!"
      />
      <InformationModal
        open={accessAccountDenied}
        onClose={setAccessAccountDenied}
        title="Oops"
        text="Request to access account denied!"
      />
      <InformationModal
        open={installEthereum}
        onClose={setInstallEthereum}
        title="Oops"
        text="Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
      />
      <InformationModal
        open={nftMinted}
        onClose={setNftMinted}
        title="Claim Successful"
        text="Your Transaction Successfully completed🎉!"
      />
      <InformationModal
        open={nftMinting}
        onClose={setNftMinting}
        title="Information"
        text="Claiming FFLY!"
      />
      <InformationModal
        open={transactionRejected}
        onClose={setTransactionRejected}
        title="Error"
        text="Transaction Rejected!"
      />
      <InformationModal
        open={transactionFailed}
        onClose={setTransactionFailed}
        title="Error"
        text="Transaction Failed!"
      />
      <InformationModal
        open={switchToMainnet}
        onClose={setswitchToMainnet}
        title="Error"
        text="Please switch to mainnet "
      />
      <InformationModal
        open={ethereumCompatibleBrowser}
        onClose={setEthereumCompatibleBrowser}
        title="Error"
        text="Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
      />
      <ConfirmationLoadingPopup
        open={confirmTransaction}
        title="Confirm Transaction"
        message="Confirm transaction to Claim FFLY"
      />
      <ConfirmationLoadingPopup
        open={mintingInProgress}
        title="Claiming In Progress"
        message="Please wait to get confirmation of the transaction from blockchain"
      />
    </div>
  );
}

export default App;
