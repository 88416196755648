import React from "react";
import Header from "./components/Header";
import OverView from "./components/OverView";
import Footer from "./components/Footer";
const Main = ({
  loadWeb3,
  account,
  tokenBalance,
  unClaimedBalance,
  claim,
  nftBalance,
  tokensPerNft,
}) => {
  return (
    <div>
      <Header loadWeb3={loadWeb3} account={account} />

      <OverView
        loadWeb3={loadWeb3}
        account={account}
        tokenBalance={tokenBalance}
        unClaimedBalance={unClaimedBalance}
        claim={claim}
        nftBalance={nftBalance}
        tokensPerNft={tokensPerNft}
      />
      <div className="container">
        <p className=" max-w-lg mx-auto mb-10 my-4 text-center">
          This NFT was created and deployed by a 3rd party for entertainment
          purposes only and as a dao for the community.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
