/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const OverView = ({
  loadWeb3,
  account,
  tokenBalance,
  unClaimedBalance,
  claim,
  nftBalance,
  tokensPerNft,
}) => {
  const claimHandler = () => {
    if (account) {
      claim();
    } else {
      loadWeb3();
    }
  };

  const btnClasses =
    "mt-6 py-1.5 px-4 rounded-sm text-white font-bold text-xl uppercase ";
  return (
    <section>
      <div className="container mt-10">
        <p className="font-bold text-3xl text-center">Launching Soon</p>
        <div className="bg-black bg-opacity-25 p-8 py-12 mt-8   mb-20">
          <h1 className="text-5xl uppercase font-bold text-center ">
            Overview
          </h1>
          <p className="text-center mt-4 text-xl capitalize  mx-auto">
            You have {nftBalance} NFTs worth{" "}
            <span className="text-red-500">
              {tokensPerNft} BNB {""}
            </span>{" "}
            each
          </p>

          <div className="mt-14 grid grid-cols-1 gap-10 ">
            {/* <div className="text-center">
              <h3 className="text-2xl font-bold">
                YOUR Force of Nature BALANCE
              </h3>
              <br />
              <p className="text-lg">
                Force of Nature <span>{Number(tokenBalance).toFixed(2)}</span>
              </p>
              <a
                href="https://app.uniswap.org/#/swap?inputCurrency=0x7f2ba02184ea19d781431c003a955d2f85cb6636&outputCurrency=ETH&chain=mainnet"
                target={"_blank"}
                className={`block   max-w-max mx-auto bg-primary-400 ${btnClasses}`}
                rel="noreferrer"
              >
           
                {"BUY TOKEN"}
              </a>
              <a
                href="https://opensea.io/collection/official-funky-flies"
                target={"_blank"}
                className={`inline-block  bg-primary-400 ${btnClasses}`}
                rel="noreferrer"
              >
                
                {"BUY NFT"}
              </a>
            </div> */}
            <div className="text-center">
              <h3 className="text-2xl font-bold">YOUR UNCLAIMED REWARDS</h3>
              <br />
              <p className="text-lg">
                BNB &nbsp;<span>{unClaimedBalance}</span>
              </p>
              <button
                className={`${
                  unClaimedBalance <= 0
                    ? " pointer-events-none bg-gray-500"
                    : "bg-primary-400"
                } ${btnClasses}`}
                onClick={claimHandler}
              >
                {account ? "Claim" : "Connect Wallet"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverView;
